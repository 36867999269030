import React from 'react'
import Layout from './Layout'
import { BotActiveProvider } from '../context/BotActiveContext'
import { MiniProvider } from '../context/MiniContext'
import { FilterProvider } from '../context/FilterContext'

export default function LayoutWrapper({ children }) {
  return (
    <MiniProvider>
      <BotActiveProvider>
        <FilterProvider>
          <Layout children={children} />
        </FilterProvider>
      </BotActiveProvider>
    </MiniProvider>
  )
}
