import React, { useEffect, useRef } from 'react'
import '../../styles/chatbot.css'
import SplitVerse from './SplitVerse'


export default function Poem({poem, hColor}) {
  const versesRef = useRef() 

  useEffect(() => {
    const verses = versesRef.current.querySelectorAll('span[aria-label]')
    randomSpacing(verses) 
  }, [poem.body, versesRef.current])

  function randomSpacing(verses){ 
    verses.forEach((verse) => {
      verse.style.wordSpacing = `${Math.random() * 20}vw`
    })
  }

  return (
    <div className="output" style={{backgroundColor: "hsl(" + hColor + "deg, 100%, 40%)"}}>
      <div className='poemtitle'>{poem.title}</div>
      <div className="verses" ref={versesRef}>
        {poem.body && poem.body.split("\n").map((verse,index) => {
          return <>
            <SplitVerse copy={verse.trim()} key={"poem" + index} />
            {"\n"}
          </>
        })}
      </div>
    </div>
  )
}
