import React, { useRef, useEffect } from 'react'
import'../../styles/chatbot.css'

export default function SplitVerse({copy}) {

  const spanRef = useRef(null);

  useEffect(() => {
    let characters
    // if (spanRef.current) {
      characters = spanRef.current.querySelectorAll('span.character');

      characters.forEach((char, index) => {
        char.offsetBottom = char.offsetTop - window.innerHeight
        
        const style = {
          animation: 'blend-in-char 0.1s forwards',
          animationDelay: `${(Math.abs(char.offsetBottom) / 600) * Math.random() * 1 + 0.33 }s`, // Adjust the multiplication factor (600 in this case) as needed, + 1 for loading time
        }
        Object.assign(char.style, style)
      })
    // }
    return () => {
      characters.forEach(char => {
        const originalStyle = {
          opacity: 0,
          animation: 'none',
        }
        Object.assign(char.style, originalStyle)
      })
    }
  }, [spanRef, copy]);

  return (
    <span aria-label={copy} ref={spanRef}>
      {copy && copy.split("").map((char, index) => {
          return <span 
            className="character"
            aria-hidden="true" 
            key={"char"+index}
          >{char}</span>
      })}
    </span>
  )
}
