import React, { useEffect } from 'react'
import { useGLTF, Environment } from '@react-three/drei'
import { navigate } from 'gatsby'
import { useMini } from '../../context/MiniContext'


export default function Logo({position, forwRef}) {
  const model = useGLTF("../../model/PAMPLOGO.glb")
  const {mini, setMini} = useMini()
   
  useEffect(() => {
    // Logo
    const hiddenObj = model.scene.children[0]
    hiddenObj.isMesh = false
    const logoMesh = model.scene.children[1]

    // const rock = model.scene.children[0]

    // logoMesh.position.x = position.x * 4
    // logoMesh.position.y = position.y * 2
    // logoMesh.position.z = position.z * 4

    // const modelMaterial = model.scene.children[0].material
    // modelMaterial.color = {r: 1, g:1, b:1}
    // modelMaterial.transmission = 0.8
    // modelMaterial.roughness = 0
    // modelMaterial.ior = 5
    // modelMaterial.thickness = 0.5
    // modelMaterial.specularIntensity = 1
    // modelMaterial.side = 2
    // modelMaterial.clearCoat = 1.0
    // modelMaterial.transparent = true
    // modelMaterial.opacity = 1
    // modelMaterial.envMapIntensity = 0.4
    // modelMaterial.emissive = {r: 0, g:0 , b:0}
    // modelMaterial.emissiveIntensity = 0.5

}, [model.scene.children ])

const handleLogoClick = () => {
  if(!mini) {
    navigate('/about')
    setTimeout(()=> setMini(true), 0)
  }
}
  return (
    <>
      {/* <Sky /> */}
      <Environment files="../../environments/logo-environment-small.hdr" />
      <primitive 
        ref={forwRef}
        object={model.scene} 
        // scale={3} ROCK
        // rotation={[Math.PI / 5, Math.PI * 1.5, 0]}
        scale={0.3} 
        position={[0, -2, 0]}
        onClick={handleLogoClick}
      ></primitive>
    </>
  )
}