import { Html, useTexture } from '@react-three/drei'
import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
// import '../../styles/experience.css'
import { navigate } from 'gatsby';
import { useFrame } from 'react-three-fiber';
import { useMini } from '../../context/MiniContext';
import { useFilter } from '../../context/FilterContext';


export default function Plane({rotation, project, position}) {
  const thumb = project.thumbnail.asset.url
  const texture = useTexture(`${thumb}`)
  const plane = useRef()
  const cGroupRef = useRef()
  const { mini, setMini } = useMini()
  const {checkedGenres, checkedFields} = useFilter()
  
  useEffect(() => plane.current.lookAt(1,1,1), [])

  const handleProjectClick = () => {
    if(!mini){
      navigate(`/projects/${project.slug.current}`)    
      setTimeout(() => setMini(true), 0)
    }
  }

  // animate 
  useFrame(({clock}) => {
  if (!mini) cGroupRef.current.rotation.y += rotation * 0.6
  })

  // cursor on hover
  const handlePointerEnter = () => {
    document.body.style.cursor = 'pointer'
  }
  const handlePointerLeave = () => {
    document.body.style.cursor = 'default'
  }

  return (
    <group ref={cGroupRef}>
      <mesh
        onClick={handleProjectClick}
        onPointerEnter={handlePointerEnter}
        onPointerLeave={handlePointerLeave}
        position-x={position.x} 
        position-z={position.z * 0.5} 
        position-y={position.y}
        ref={ plane } 
        >
        <planeGeometry args={ [9, 6] } />
        <meshBasicMaterial 
          side={THREE.DoubleSide} 
          map={texture}
          transparent={true}
          opacity={
            project.mediumTags.some(genre => checkedGenres.includes(genre)) &&
            project.fieldTags.some(industry => checkedFields.includes(industry)) ? 
            1 : 0.1
          }
          />
        <Html 
          position={[4.5, 3.4, 0]} 
          wrapperClass="label"
          occlude
          // visible={!mini}
          >
          <small style={{
            opacity: 
              project.mediumTags.some(genre => checkedGenres.includes(genre)) &&
              project.fieldTags.some(industry => checkedFields.includes(industry)) ?
              1 : 0,
          }}>{project.title}</small>
        </Html>
      </mesh>
    </group>
  )
}
