import React, { createContext, useContext, useState } from 'react';

const BotActiveContext = createContext();

export const BotActiveProvider = ({ children }) => {
  const [botActive, setBotActive] = useState(false);

  const toggleBotActive = () => {
    setBotActive(prevBotActive => !prevBotActive);
  };

  return (
    <BotActiveContext.Provider value={{ botActive, toggleBotActive }}>
      {children}
    </BotActiveContext.Provider>
  );
};

export const useBotActive = () => useContext(BotActiveContext);
