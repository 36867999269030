import React, { useState } from 'react'
import '../styles/filters.css'
import Button from './Button'
import { graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby'
import { useBotActive } from '../context/BotActiveContext'
import { useMini } from '../context/MiniContext'

export default function Filters({handleBot}) {

  const {botActive, toggleBotActive} = useBotActive()
  const {mini, setMini} = useMini();

  const handleAboutClick = () => {
    navigate(`/about`)
    if(botActive) {toggleBotActive()}
    if (!mini) {setMini(true)}
  }
  const handleCommunityClick = () => {
    navigate(`/friends`)
    if(botActive) {toggleBotActive()}
    if (!mini) {setMini(true)}
  }

  return (
      <div className="bottomnav flexbox">
        <Button handleOnClick={handleAboutClick} >
          <span>About</span>
        </Button>
        <Button handleOnClick={handleCommunityClick} >
          <span>Community</span>
        </Button>
      </div>
  )
}
