import React, { createContext, useContext, useState } from 'react'

const MiniContext = createContext()

export const MiniProvider = ({ children }) => {
  const [mini, setMini] = useState(false)
  // const [isOval, setIsOval] = useState(true)

  // const toggleMini = () => {
  //   // setMini(prevMini => !prevMini);
  //   if (mini) {
  //     setMini(prevMini => !prevMini) 
  //     console.log("magnified");
  //   } else {
  //     setMini(prevMini => !prevMini) 
  //     console.log("minified");
  //   }
  //   // if (!mini) setIsOval(true)
  // }

  return (
    <MiniContext.Provider value={{ mini, setMini/*, toggleMini */}}>
      {children}
    </MiniContext.Provider>
  )
}

export const useMini = () => useContext(MiniContext)
