import React from 'react'

export default function Button({children, handleOnClick, style, cName}) {

  return (
    <div 
      className={`button ${cName}`}
      onClick={handleOnClick} 
      style={style}
    >
      <small>
      {children}
      </small>
    </div>
  )
}
