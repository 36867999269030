import React, { createContext, useContext, useState } from 'react'

const FilterContext = createContext()

export const FilterProvider = ({ children }) => {
  const [checkedGenres, setCheckedGenres] = useState(['event', 'photography', 'spatial design', 'video', 'visual identity', 'other'])
  const [checkedFields, setCheckedFields] = useState(['architecture', 'cinema', 'community', 'fashion', 'music', 'other'])

  return (
    <FilterContext.Provider value={{ 
        checkedGenres, 
        setCheckedGenres, 
        checkedFields,
        setCheckedFields
    }}>
      {children}
    </FilterContext.Provider>
  )
}

export const useFilter = () => useContext(FilterContext)
