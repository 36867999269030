import React, { useEffect, useState } from 'react'

export default function Loader({ isLoading }) {

  return (
    <div className={`loader ${isLoading ? 'visible' : 'hidden'}`}>
      <small style={{animation: isLoading ? 'blink 1.5s infinite' : 'none'}}>Loading Pamp Network</small>
    </div>
  )
}
