import React from 'react'

export default function SquareIcon({isFiltered}) {
  return (
    <span className="square">

      <svg  width="8" height="8" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="8" height="8" fill={ !isFiltered ? "#D3D3D3" : "#05FF00" }/>
      </svg>
    </span>
  )
}
