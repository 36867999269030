exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-friends-js": () => import("./../../../src/pages/friends.js" /* webpackChunkName: "component---src-pages-friends-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-videotest-js": () => import("./../../../src/pages/videotest.js" /* webpackChunkName: "component---src-pages-videotest-js" */),
  "component---src-templates-projects-jsx": () => import("./../../../src/templates/projects.jsx" /* webpackChunkName: "component---src-templates-projects-jsx" */)
}

