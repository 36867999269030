import React from "react"
import LayoutWrapper from "./src/components/LayoutWrapper";

export const wrapPageElement = ({ element, props }) => {
  const excludedPaths = ["/tv", "/tv/"]; // Check both variations

  if (excludedPaths.includes(props.location.pathname)) {
    return element;
  }

  // Wrap all other pages with the layout wrapper
  return <LayoutWrapper {...props}>{element}</LayoutWrapper>;
};
