import React, { useState } from 'react'
import '../../styles/chatbot.css'
import InputForm from './InputForm'
import Poem from './Poem'
import { graphql, useStaticQuery } from 'gatsby'

export default function PoemBot() {
  const data = useStaticQuery(graphql`
  {    
    allSanityPoembot {
      nodes {
        _id
        body
        title
        author
      }
    }
  }`)
  const [poem, setPoem] = useState({})
  const [keyword, setKeyword] = useState(null)
  // const [randomIndex, setRandomIndex] = useState(0)
  const [hColor, setHColor] = useState(0)
  const [isOpen, setIsOpen] = useState(true)

  const outputPoem = (keyWord) => {
    if(keyWord !== '') {
      const curRandomIndex = Math.floor(Math.random() * data.allSanityPoembot.nodes.length)
      // setRandomIndex(curRandomIndex)
      setKeyword("\"" + keyWord + "\"")
      setHColor(Math.floor(Math.random() * 360))
      setPoem({
        title: data.allSanityPoembot.nodes[curRandomIndex].title, 
        body: data.allSanityPoembot.nodes[curRandomIndex].body,
        author: data.allSanityPoembot.nodes[curRandomIndex].author,
      })
    } else {
      console.log("no new keyword");
      // here we could do a component remount for new poem layout
    }
  }

  const handlePoemBox = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      {keyword && <Poem poem={poem} hColor={hColor}/>}
      <div className='poemcontainer'>
        {keyword && isOpen && 
          <div className='poem'>
            <small className='keyword'>{keyword}</small>
            <small>{poem.title}</small>
            <small className='body'>{poem.body}</small>
            {poem.author && <small className='author'>Author: {poem.author}</small>}
          </div>
        }
        <InputForm 
          outputPoem={outputPoem} 
          handlePoemBox={handlePoemBox} 
          isOpen={isOpen} 
        />
      </div>
    </>
  )
}
