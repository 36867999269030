import { Html } from '@react-three/drei'
import React, { useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { useFrame } from 'react-three-fiber'
import { useMini } from '../../context/MiniContext'


export default function Collaborator({collaborator, rotation, position}) {

  const collab = useRef()
  const cGroupRef = useRef()
  const [pointerIn, setPointerIn] = useState(false)
  const {mini, setMini} = useMini()

  // useEffect(() => collab.current.lookAt(0,0,0), [])

  const handlePointerEnter = () => {
    document.body.style.cursor = 'pointer'
    setPointerIn(true)
  }
  const handlePointerLeave = () => {
    document.body.style.cursor = 'default'
    setPointerIn(false)
  }

  const handleCollabClick = () => {
    if(!mini){
      navigate(`/friends`)
      setTimeout(()=> setMini(true), 0)
    }
  }

  // animate 
  useFrame(() => {
    if (!mini) cGroupRef.current.rotation.y += rotation * 0.5
  })

  return (
      <group ref={cGroupRef}>
        <mesh 
          onClick={handleCollabClick}
          onPointerEnter={handlePointerEnter}
          onPointerLeave={handlePointerLeave}
        
          position-x={position.x} 
          position-y={position.y} 
          position-z={position.z * 0.8} 
          ref={ collab }
        >
          <sphereGeometry args={[0.4, 10 , 10]} />
          <meshBasicMaterial color={'#05FF00'} />
          <Html
            position={[0, 0.8, 0]} 
            wrapperClass={ pointerIn ? 'label collab active': 'label collab' }
            center
            occlude
          >
            <small>{collaborator.artistName}</small>
          </Html>
        </mesh>
      </group>
  )
}
