import React from 'react'
import Button from './Button'
import { navigate } from 'gatsby'
import { useBotActive } from '../context/BotActiveContext'
import { useMini } from '../context/MiniContext'

export default function Navigation({handleBot}) {

  const {botActive, toggleBotActive} = useBotActive()
  const {mini, setMini} = useMini();

  const handleGalleryClick = () => {
    navigate(`/gallery`)
    // document.querySelector(".nav .button.gallery").classList.add("acc")
    if(botActive) {toggleBotActive()}
    if (!mini) {setMini(true)}
  }

  return (
    <div className="nav flexbox">
      <Button 
        // cName={"gallery"}
        handleOnClick={handleGalleryClick}
      > IMAGE GALLERY</Button>
      <Button 
        cName={botActive ? 'acc' : ''}
        handleOnClick={handleBot} 
      >{botActive ? 'Exit Chat' : 'CHAT POETICA'}</Button>
    </div>
  )
}
