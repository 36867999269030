import React, { Suspense, useState } from 'react'
import '../styles/variables.css'
import '../styles/global.css'
import '../styles/experience.css'
import Navigation from './Navigation'
import PoemBot from './chatbot/PoemBot'
import { Canvas } from "react-three-fiber"
import * as THREE from 'three'
import Experience from './experience/Experience'
import Perspective from './Perspective'
import Filters from './Filters'
import BottomNav from './BottomNav'
import { useBotActive } from '../context/BotActiveContext'
import { navigate } from 'gatsby'
import { useMini } from '../context/MiniContext';
import Loader from './experience/Loader'

export default function Layout({ children }) {
  const { mini, setMini } = useMini();
  const { botActive, toggleBotActive } = useBotActive();
  const [isLoading, setIsLoading] = useState(true);

  
  const handleBot = () => toggleBotActive()

  const handleCanvasClick = () => {
    if(mini) setMini(false);
    setTimeout(()=> navigate('/'), 1000)
  }

  return (
    // <> has id #gatsby-focus-wrapper
    <> 
      <Loader isLoading={isLoading} />
      
      <Navigation handleBot={handleBot} />
      {botActive && <PoemBot/>}


      <Canvas
        className={ !mini ? 'canvascomp' : 'canvascomp mini' }
        dpr={[1, 2]}
        gl={{
            antialias: true,
            toneMapping: THREE.CineonToneMapping
        }}
        camera={{
            fov: 10, 
            near: 20, 
            far: 1000, 
            position: [0,0,90],
            rotation: [Math.PI * 0.15, Math.PI * 0.1]
        }}
        onClick={() => mini && handleCanvasClick()}
        onCreated={() => setTimeout(() => {setIsLoading(false)}, 1500)}
      >
          <Experience 
            // isOval={isOval} 
          />

      </Canvas>

      { children }

      {/* {!mini && <Perspective />} */}
      {!mini && <Filters handleBot={handleBot} />}
      <BottomNav handleBot={handleBot} />
    </>
  )
}
