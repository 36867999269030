import React, { useState } from 'react'
import '../styles/filters.css'
import Button from './Button'
import SquareIcon from './SquareIcon'
// import { useBotActive } from '../context/BotActiveContext'
import { useFilter } from '../context/FilterContext'

export default function Filters({handleBot}) {
  const [isOpen, setIsOpen] = useState(false)
  // const {botActive, toggleBotActive} = useBotActive()
  const {checkedGenres, setCheckedGenres} = useFilter()
  const {checkedFields, setCheckedFields} = useFilter()
  const [genres, setGenres] = useState(['event', 'photography', 'spatial design', 'video', 'visual identity', 'other'])
  const [fields, setFields] = useState(['architecture', 'cinema', 'community', 'fashion', 'music', 'other'])

  const handleFilters = () => {
    setIsOpen(prevOpen => !prevOpen)
  }

  const handleCheckboxChange = (setCheckedState, checkedState, value) => {
    setCheckedState(value)
    // const currentIndex = checkedState.indexOf(value);
    // const newCheckedState = [...checkedState];

    // if (currentIndex === -1) {
    //   newCheckedState.push(value);
    // } else {
    //   newCheckedState.splice(currentIndex, 1);
    // }
    // setCheckedState(newCheckedState);    
  }


  return (
    <>
      <div className='filtercontainer'>
        <div className='infocontent flexbox' style={{display: isOpen ? '' : 'none'}} >
          <small className="filtergroup">
            <span className='type'>Medium</span>
            <ul>
              <li className={checkedGenres.length === genres.length ? "filtered" : ""}>
                <label>
                  <input
                    type="checkbox"
                    value={'All'}
                    onChange={() => handleCheckboxChange(setCheckedGenres, checkedGenres, genres)}
                    style={{ display: 'none' }}
                  />
                  <SquareIcon isFiltered={checkedGenres.length === genres.length} />
                  <span>All</span>
                </label>
              </li>
              {genres && genres.map((genre,index) => 
                <li 
                  className={checkedGenres.includes(genre) && checkedGenres.length === 1 ? "filtered" : ""} 
                  key= {genre+index}
                  >
                  <label>
                    <input
                      type="checkbox"
                      value={genre}
                      onChange={() => handleCheckboxChange(setCheckedGenres, checkedGenres, [genre])}
                      style={{ display: 'none' }}
                    />
                    <SquareIcon isFiltered={checkedGenres.includes(genre) && checkedGenres.length === 1} /> 
                    <span>{genre}</span>
                  </label>
                </li>
      
              )}
            </ul>
          </small>
          <small className="filtergroup">
            <span className='type'>Field</span>
            <ul>
              <li className={checkedFields.length === fields.length ? "filtered" : ""}>
                <label>
                  <input
                    type="checkbox"
                    value={'All'}
                    onChange={() => handleCheckboxChange(setCheckedFields, checkedFields, fields)}
                    style={{ display: 'none' }}
                  />
                  <SquareIcon isFiltered={checkedFields.length === fields.length } />
                  <span>All</span>
                </label>
              </li>
              {fields && fields.map((field,index) => 
                <li 
                  className={checkedFields.includes(field) && checkedFields.length === 1 ? "filtered" : ""} 
                  key= {field+index}
                >
                  <label>
                    <input
                      type="checkbox"
                      value={field}
                      onChange={() => handleCheckboxChange(setCheckedFields, checkedFields, [field])}
                      style={{ display: 'none' }}
                    />
                    <SquareIcon isFiltered={checkedFields.includes(field) && checkedFields.length === 1} /> 
                    <span>{field}</span>
                  </label>
                </li>
              )}
            </ul>
          </small>
        </div>
        <div className="flexbox">
          <Button 
            handleOnClick={handleFilters} 
            cName={isOpen ? 'acc' : ''}
          >
            <span>Filters</span>
            <span className='filtericon'>{isOpen ? "–" : "+"}</span>
          </Button>
        </div>
      </div>
    </>
  )
}
